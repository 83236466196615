import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBanner from "../components/index/header_jumbotron"
import CategoryTitle from "../components/index/categorytitle"


const Cozumler = () => (
	<Layout>
		<SEO title="Solutions" />
		<div id="cozumler" className="inlinePage center-h footerFixed">
			<div className="container-fluid w80 col-xs-w95">
				<CategoryTitle title="SERENAY" subTitle="Solutions" description="Solutions" isDark={false} position="inline" />
				<div className="row">
						<div className="flex w100 left h100 col-xs-block customHeight">
							<HeaderBanner title="Special Software Solutions" description="" icon="icon-code1" linkTo="/special-software" />
							<HeaderBanner title="Private Server Services" description="" icon="icon-web-hosting" linkTo="/private-server-services" />
							<HeaderBanner title="Technologies We Use" description="" classAdd="col-xs-w100" icon="icon-heart2" linkTo="/technologies" />
						</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Cozumler
